import store from '@/state/store'
import axios from 'axios'
import { authHeader } from '../helpers/auth-header'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            async beforeResolve(routeTo, routeFrom, next) {
                try {
                    const userData = localStorage.getItem('user');
                    console.log(`${process.env.VUE_APP_APIURL}/admin/logout`);
                    const item = await axios.post(`${process.env.VUE_APP_APIURL}/admin/logout`, userData,  {
                        headers: authHeader(),
                    });
                    console.log(item);
                } catch (err) {
                    console.log(err);
                }

                next('/login');
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },

    // profile
    {
        path: '/profile',
        name: 'Profile',
        meta: { authRequired: false },
        component: () => import('../views/pages/account/profile')
    },

    // Admin
    {
        path: '/admin',
        name: 'Admin',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin/index')
    },
    {
        path: '/admin/form/:id',
        name: 'Admin Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin/form')
    },
    {
        path: '/admin/form/:id/password',
        name: 'Admin Edit Password',
        meta: { authRequired: true },
        component: () => import('../views/pages/admin/edit_password')
    },

    // Tutor
    {
        path: '/service_professionals',
        name: 'Tutor',
        meta: { authRequired: true },
        component: () => import('../views/pages/tutor')
    },
    {
        path: '/service_professional/form/:id',
        name: 'Tutor Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/tutor/form')
    },
    {
        path: '/service_professional/view/:id',
        name: 'Tutor View',
        meta: { authRequired: true },
        component: () => import('../views/pages/tutor/view-detail')
    },

    // Parent Request
    {
        path: '/parent_request',
        name: 'Parent Request',
        meta: { authRequired: true },
        component: () => import('../views/pages/parent_request/index')
    },
    {
        path: '/parent_request/form/:id',
        name: 'Parent Request Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/parent_request/form')
    },
    {
        path: '/parent_request/view/:id',
        name: 'Parent Request View',
        meta: { authRequired: true },
        component: () => import('../views/pages/parent_request/view-detail')
    },

    // Assignment
    {
        path: '/assignment',
        name: 'Assignment',
        meta: { authRequired: true },
        component: () => import('../views/pages/assignment')
    },
    {
        path: '/assignment/form/:id',
        name: 'Assignment Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/assignment/form')
    },

    
    // Field Management
    {
        path: '/field_management/service',
        name: 'Service',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/service')
    },
    {
        path: '/field_management/service/form/:id',
        name: 'Service Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/service_form')
    },
    {
        path: '/field_management/subject',
        name: 'Subject',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/subject')
    },
    {
        path: '/field_management/subject/form/:id',
        name: 'Subject Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/subject_form')
    },
    {
        path: '/field_management/service_provider/',
        name: 'Service Provider',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/service_provider')
    },
    {
        path: '/field_management/service_provider/form/:id',
        name: 'Service Provider Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/service_provider_form')
    },
    {
        path: '/field_management/student_grade',
        name: 'Student Grade',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/student_grade')
    },
    {
        path: '/field_management/student_grade/form/:id',
        name: 'Student Grade Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/student_grade_form')
    },
    {
        path: '/field_management/subject_duration',
        name: 'Subject Duration',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/subject_duration')
    },
    {
        path: '/field_management/subject_duration/form/:id',
        name: 'Subject Duration Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/subject_duration_form')
    },
    {
        path: '/field_management/subject_frequency',
        name: 'Subject Frequency',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/subject_frequency')
    },
    {
        path: '/field_management/subject_frequency/form/:id',
        name: 'Subject Frequency Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/subject_frequency_form')
    },
    {
        path: '/field_management/tutor_type',
        name: 'Tutor Type',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/tutor_type')
    },
    {
        path: '/field_management/tutor_type/form/:id',
        name: 'Tutor Type Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/tutor_type_form')
    },
    {
        path: '/field_management/student_grade_category',
        name: 'Student Grade Category',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/student_grade_category')
    },
    {
        path: '/field_management/student_grade_category/form/:id',
        name: 'Student Grade Category Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/field_management/student_grade_category_form')
    },

    // Setting - Role
    {
        path: '/setting/general',
        name: 'General',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/general')
    },
    {
        path: '/setting/role',
        name: 'Role',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/role')
    },
    {
        path: '/setting/role/form/:id',
        name: 'Role Form',
        meta: { authRequired: true },
        component: () => import('../views/pages/setting/role_form')
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'Error-404',
        meta: { authRequired: false },
        component: () => import('../views/pages/utility/error-404')
    },
    // {
    //     path: '/pages/error-500',
    //     name: 'Error-500',
    //     meta: { authRequired: true },
    //     component: () => import('../views/pages/utility/error-500')
    // },
]
